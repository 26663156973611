import React, { useCallback, useState, useMemo, memo } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { graphql } from "gatsby";
import { useWindowScroll, useMeasure } from "react-use";
import ProjectPreviewGrid from "../components/project-preview-grid";
import BackgroundImage from "gatsby-background-image";
import { AnchorLink } from "gatsby-plugin-anchor-links";

export const query = graphql`
  query IndexPageQuery {
    allProjectsJson(filter: { slug: { ne: "z-project" } }) {
      nodes {
        slug
        thumbnail
        title
      }
    }
    sortingJson {
      sorting
    }
    allFile(filter: { relativePath: { regex: "/th/.*/" } }) {
      nodes {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
        relativePath
      }
    }
    heroMobileImage: file(
      relativePath: { eq: "hero-image/hero-illustration.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 1600, fit: COVER, cropFocus: WEST) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      relativePath
    }
    heroDesktopImage: file(
      relativePath: { eq: "hero-image/hero-illustration.png" }
    ) {
      ...FluidImageFragment
    }
  }
`;

function sortWithOrder(needSort, order) {
  return needSort.sort(function(a, b) {
    return order.indexOf(a.slug) < order.indexOf(b.slug) ? -1 : 1;
  });
}

const numberOfProjectsShownByDefault = 12;

function IndexPage({ data }) {
  const { allProjectsJson, sortingJson } = data;
  const projects = allProjectsJson.nodes;
  const { sorting: sortingArray } = sortingJson;
  const images = data.allFile.nodes;

  const heroImages = useMemo(
    () => [
      data.heroMobileImage.childImageSharp.fluid,
      {
        ...data.heroDesktopImage.childImageSharp.fluid,
        media: "(min-width: 425px)"
      }
    ],
    [
      data.heroDesktopImage.childImageSharp.fluid,
      data.heroMobileImage.childImageSharp.fluid
    ]
  );

  const { y } = useWindowScroll();
  const [ref, { height }] = useMeasure();
  const ratio = y / (height * 0.2);
  const scrollRatio = ratio < 1 ? ratio : 1;

  return (
    <Layout scrollRatio={scrollRatio}>
      <SEO keywords={["contact", "about", "projects"]} title="Home" />
      <Hero heroDivRef={ref} heroImages={heroImages} />
      <main className="container max-w-6xl mx-auto">
        <Projects
          images={images}
          projects={projects}
          sortingArray={sortingArray}
        />
        <About />
        <Contact />
      </main>
    </Layout>
  );
}

const Hero = memo(({ heroDivRef, heroImages }) => {
  return (
    <BackgroundImage
      Tag="section"
      className={"relative h-screen hero"}
      fluid={heroImages}
      id="home"
      loading={"eager"}
      style={{
        backgroundSize: "cover",
        backgroundPositionX: "0%",
        backgroundPositionY: "bottom",
        backgroundRepeat: "no-repeat"
      }}
    >
      <AnchorLink to="/#portfolio">
        <div className="absolute w-full arrow-contain">
          <div className="arrow-t mx-auto">
            <span></span>
          </div>
          <div className="arrow mx-auto">
            <span></span>
          </div>
        </div>
      </AnchorLink>

      <div
        className="container max-w-6xl justify-center flex items-center h-full mx-auto text-center"
        ref={heroDivRef}
      >
        <div
          className="intro-text mb-40 mt-4 md:mt-30 md:mb-56 lg:mb-48 px-6 "
          style={{ width: "600px" }}
        >
          <h1 className="mb-3 mt-0 text-xl">Hi there!</h1>
          <p className="font-medium text-lg mb-4">
            I'm Sonia, a Global Digital Design Lead 
            <br />and Design Manager
            based&nbsp;in&nbsp;London.
            <br />
            Welcome to my portfolio and&nbsp;of&nbsp;course,
            <br /> feel free to reach out :)
          </p>
          <AnchorLink
            className="intro text-lg mt-3 border-dark text-dark border rounded-full px-3 py-1 font-medium"
            to="/#contact"
          >
            Let's chat!
          </AnchorLink>
        </div>
      </div>
    </BackgroundImage>
  );
});

const Projects = memo(({ projects, images, sortingArray }) => {
  const sortedProjects = useMemo(() => sortWithOrder(projects, sortingArray), [
    projects,
    sortingArray
  ]);
  const visibleProjects = useMemo(
    () => sortedProjects.slice(0, numberOfProjectsShownByDefault),
    [sortedProjects]
  );
  const moreProjects = useMemo(
    () => sortedProjects.slice(numberOfProjectsShownByDefault),
    [sortedProjects]
  );

  const imagesSharp = useMemo(() => {
    return images.reduce((accumulator, image) => {
      return { ...accumulator, [image.relativePath]: image };
    }, {});
  }, [images]);

  const [showMore, setShowMore] = useState(false);
  const handleShowMore = useCallback(() => {
    setShowMore(true);
  }, []);

  return (
    <>
      <span
        aria-hidden="true"
        className="block relative"
        id="portfolio"
        style={{ top: "-50px", height: 0, width: 0 }}
      >
        &nbsp;
      </span>
      <section className="mt-12" id="gallery">
        <h1 className="padding">Portfolio</h1>
        <div className="overflow-hidden">
          <div className="flex flex-wrap -mx-2">
            <ProjectPreviewGrid
              imagesSharp={imagesSharp}
              projects={visibleProjects}
            />
            {!showMore && (
              <div className="mx-auto my-6">
                <button className="cta" onClick={handleShowMore}>
                  More projects
                </button>
              </div>
            )}
          </div>
          <div
            className={`flex flex-wrap -mx-2 collapsible ${showMore &&
              "expanded"}`}
          >
            <ProjectPreviewGrid
              imagesSharp={imagesSharp}
              projects={moreProjects}
            />
          </div>
        </div>
      </section>
      <hr className="border-t border-gray-300 bg-light border-0 mt-8" />
    </>
  );
});

const About = memo(() => {
  return (
    <>
      <span
        className="block relative"
        id="about"
        style={{ top: "-50px" }}
      ></span>
      <section className="padding">
        <h1>About</h1>
        <div>
          <p>
            All in all, I have quite a traditional Designer background: after
            seven years of hard-doodling practice on notebooks and school
            tables, design was definitely my passion. Therefore after high
            school, I naturally studied economics and attended a business
            school... But this is about the time when I started doing my first
            commissioned works. Further non-design-related jobs brought me to
            create various communications materials, and I developed in parallel
            a real taste for web design and vector illustrations.
          </p>
          <p>
            Long story short, I'm now a full-time Global Digital Design Lead with many years of experience working on multidisciplinary projects including digital strategy, UX design, digital content creation, project & team management. I'm currently responsible for bringing Reckitt's Nutrition creative brand experience strategy to life, delivering outstanding designs across eCommerce, digital channels, and experiential consumer touchpoints.
          </p>
          <p>
            Please feel free to get in touch! You'll find my contact details
            below. Cheers!
          </p>
        </div>
        <div className="mt-8 mb-8 pb-5">
          <a
            className="cta"
            href="/documents/CV-Sonia_Lahcene.pdf"
            target="blank"
          >
            Download my CV
          </a>
        </div>
      </section>
      <hr className="border-t border-gray-300 bg-light border-0 mt-8" />
    </>
  );
});

const Contact = memo(() => {
  return (
    <>
      <span
        className="block relative"
        id="contact"
        style={{ top: "-50px" }}
      ></span>
      <section className="padding pb-12">
        <h1>Contact</h1>
        <ul className="ml-6">
          <li className="mb-3 contact-trigger">
            <a
              href="mailto:contact@sonialahcene.com"
              target="blank"
              title="Contact Sonia Lahcene"
            >
              <span className="icon-mail text-xl mr-2 align-text-bottom"></span>
              Contact me at{" "}
              <span className="font-medium">contact@sonialahcene.com</span>
            </a>
          </li>
          <li className="mb-3 contact-trigger">
            <a
              href="http://uk.linkedin.com/in/sonialahcene"
              target="blank"
              title="LinkedIn Sonia Lahcene"
            >
              <span className="icon-in text-xl mr-2 align-text-bottom"></span>
              Find me on <span className="font-medium">LinkedIn</span>
            </a>
          </li>
          <li className="mb-3 contact-trigger">
            <a
              href="https://www.behance.net/sonialahcene"
              target="blank"
              title="Behance Sonia Lahcene"
            >
              <span className="icon-be text-xl mr-2 align-text-bottom"></span>
              Check out my <span className="font-medium">Behance</span>
            </a>
          </li>
        </ul>
      </section>
    </>
  );
});

export default IndexPage;
