import React, { memo } from "react";
import ProjectPreview from "./project-preview";

const ProjectPreviewGrid = memo(function NonMemoProjectPreviewGrid({
  projects,
  imagesSharp
}) {
  return projects.map(({ slug, thumbnail, title }) => {
    return (
      <ProjectPreview
        key={slug}
        slug={slug}
        thumbnail={imagesSharp[thumbnail].childImageSharp.fluid}
        title={title}
      />
    );
  });
});

export default ProjectPreviewGrid;
