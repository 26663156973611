import PropTypes from "prop-types";
import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";

function ProjectPreview({ slug, title, thumbnail, backgroundColor }) {
  const isSafari =
    typeof navigator !== "undefined"
      ? /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
      : false;

  return (
    <Link
      className="w-full px-2 py-2 sm:w-1/2 md:w-1/3 project-preview"
      to={`/portfolio/${slug}`}
    >
      <div className="relative trigger overflow-hidden th-background">
        <Img
          alt={title}
          backgroundColor={backgroundColor}
          className="w-full h-96 sm:h-auto object-cover thumbnail"
          fluid={thumbnail}
          loading={isSafari ? "eager" : "lazy"}
        />
        <div className="touch description-touch">
          <h2 className="text-lg mb-0 font-bold text-white leading-none">
            {title}
          </h2>
        </div>
        <div className="absolute z-10 bottom-0 w-full h-full flex items-center text-light target overlay">
          <div className="p-4 flex flex-col items-center m-auto w-auto text-center th-trigger">
            <h2 className="tracking-wide text-3xl font-medium leading-none mb-4">
              {title}
            </h2>
            <button className="border-2 border-light cta-preview px-2 py-1">
              View more
            </button>
          </div>
        </div>
      </div>
    </Link>
  );
}

ProjectPreview.propTypes = {
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default ProjectPreview;
